<template>
  <div class="basic-info">
    <div class="header">
      <div class="header_top">
        <img src="../../assets/content.svg" alt />
        <span class="center" @click="goBack">内容管理</span>
        <img src="../../assets/right.png" alt />
        <span class="center">内容详情</span>
      </div>
    </div>
    <el-card>
      <div class="text item">
        <el-row>
          <el-col :span="12" class="text-align">编号：{{ detailInfo.code }}</el-col>
          <el-col :span="12" class="text-align">名称：{{ detailInfo.name }}</el-col>
          <el-col :span="12" class="text-align">备注：{{ detailInfo.remark }}</el-col>
          <el-col :span="12" class="text-align">更新时间：{{ detailInfo.updateTime }}</el-col>
        </el-row>
        <div style="text-align: center; margin-bottom: 10px">
          <el-radio-group v-model="style" style="margin: 0 auto">
            <el-radio-button label="PC">电脑</el-radio-button>
            <el-radio-button label="MOBILE">手机</el-radio-button>
          </el-radio-group>
        </div>

        <div :class="{ mb_view: style === 'MOBILE', pc_view: style === 'PC' }" style>
          <div class="ios-notch" style>
            <svg class="docs-demo-device__ios-notch" viewBox="0 0 219 31">
              <path
                d="M0 1V0h219v1a5 5 0 0 0-5 5v3c0 12.15-9.85 22-22 22H27C14.85 31 5 21.15 5 9V6a5 5 0 0 0-5-5z"
                fill-rule="evenodd"
              />
            </svg>
          </div>
          <iframe
            v-show="style === 'MOBILE'"
            id="viewIframe"
            width="100%"
            height="100%"
            :src="src"
            frameborder="0"
          ></iframe>
          <iframe
            v-show="style === 'PC'"
            id="myIframe"
            @load="load($event)"
            :src="src"
            frameborder="0"
            width="100%"
            height="100%"
          ></iframe>
        </div>

        <!-- <div
        class="mce-content-body"
        style="
          border-radius: 4px;
          border: 1px solid #ebeef5;
          background-color: #fff;
          overflow: hidden;
          color: #303133;
          transition: 0.3s;
          box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
          padding: 20px;
          height:100%
        "
        v-html="detailInfo.text"
      >
        </div>-->
      </div>
    </el-card>
  </div>
</template>
<script>
import { api } from '../../api/base/index';
import { generatePath } from '../../api/base/index';
// import config from "../../config/config";
export const request = api('/content');
export default {
  data () {
    return {
      style: 'PC',
      code: '',
      detailInfo: {
        code: '',
        name: '',
        text: '',
        remark: '',
        updateTime: '',
        updateUser: '',
        state: ''
      },
      src: ''
    };
  },

  created () {
    this.code = this.$route.query.code;
    if (this.$route.query.type) {
      this.style = 'MOBILE';
    }
    request('content.describe.json')({ code: this.code }).then(res => {
      this.detailInfo = res;
      this.src = generatePath() + 'content/' + res.code + '/document.html';
    });
  },
  methods: {
    load (event) {
      console.log(event);
      window.iFrameResize({ log: true, checkOrigin: false }, '#myIframe');
    },
    goBack () {
      window.history.go(-1);
    }
  }
};
</script>
<style>
.mb_view {
  /* border: 1px solid rgb(204, 204, 204); */
  height: 790px !important;
  transform: scale(0.8);
  width: 414px;
  transform-origin: top center;
  margin: 0 auto;
  background: url(./iphone-device-skin.png);
  background-size: 100%;
  padding: 61px 16px;
  /* border-radius: 86px; */
  overflow: hidden;
}
.ios-notch {
  width: 187px;
  margin: 0 auto;
  position: absolute;
  left: 133px;
  margin-top: -46px;
  display: none;
}
.mb_view .ios-notch {
  display: block;
}
.pc_view {
  margin: 0 auto;
  /* height: 100vh; */
  height: 100%;
}
.mce-content-body img,
mce-content-body table {
  max-width: 100%;
}
.mce-content-body table tr:first-child {
  background: #f7f7f7;
}
.mce-content-body table tr:last-child {
  background: #fff;
}
</style>
<style lang="scss" scoped>
.basic-info .last-breadcrumb {
  color: rgba(0, 0, 0, 0.8);
}
.basic-info .font-img {
  font-size: 26px;
  cursor: pointer;
}

.img-resource {
  width: 300px;
  height: 250px;
  margin: 0 auto;
}
.card-header {
  display: flex;
  font-size: 14px;
  align-items: center;
}
.card-icon {
  display: flex;
  width: 4px;
  height: 15px;
  background: #659fe5;
  margin-top: 3px;
  margin-right: 10px;
}
.card-title {
  margin-right: 8px;
}
.card-btn {
  cursor: pointer;
}
.text {
  font-size: 14px;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}
.text-align {
  text-align: left;
  line-height: 32px;
  padding-bottom: 8px;
}
.line-inherit {
  line-height: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.marginBottom {
  margin-bottom: 12px;
}
.statusPointer {
  cursor: pointer;
  margin-right: 12px;
  font-size: 14px;
}
.aType {
  color: #659fe5;
  cursor: pointer;
}
.tooltipClas {
  width: 250px;
}
.basic-info {
  .warning {
    color: #e6a23c;
  }
  .success {
    color: #67c23a;
  }
  .danger {
    color: #f56c6c;
  }
  .info {
    color: #409eff;
  }
}
.defaultTips {
  display: inline-block;
  margin: 0 8px;
  span {
    display: inline-block;
    font-size: 12px;
    color: red;
    line-height: 32px;
    text-align: center;
  }
}
</style>
